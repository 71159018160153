// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

export const fasSlice = createSlice({
  name: 'fas',
  initialState: {
    custID: '', 
    authInfo : {}
  },
  reducers: {
    setCustID: (state, action) => {
      state.custID = action.payload      
    },
  setAuthInfo: (state, action) => {
      state.authInfo = action.payload
    }
  }
})

export const { setCustID, setAuthInfo } = fasSlice.actions

export default fasSlice.reducer
