// ** Auth Endpoints
export default {
  //loginEndpoint: '/jwt/login',
  //refreshEndpoint: '/jwt/refresh-token',
  //loginEndpoint: 'http://localhost:8080/auth/login',
  //loginEndpoint: 'https://fas.kfr.co.kr/KFR_FAS_API/auth/login',
  loginEndpoint: 'https://kapi.kfr.co.kr/auth/login',
  registerEndpoint: '/jwt/register',
  //refreshEndpoint: 'http://localhost:8080/auth/refresh-token',  
  //refreshEndpoint: 'https://fas.kfr.co.kr/KFR_FAS_API/auth/refresh-token',  
  refreshEndpoint: 'https://kapi.kfr.co.kr/auth/refresh-token',  
  logoutEndpoint: '/jwt/logout',

  // ** This will be prefixed in authorization header with token
  // ? e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // ** Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken'
}
