// ** Reducers Imports
import layout from './layout'
import navbar from './navbar'
import auth from './authentication'
import fas from './fas'

const rootReducer = { 
    auth, 
    navbar, 
    layout,
    fas
}

export default rootReducer
